import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  BarChartOutlined,
  DashboardOutlined,
  FundProjectionScreenOutlined,
  InsertRowAboveOutlined,
  PartitionOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { CollapseType } from 'antd/lib/layout/Sider';
// import './SiderMenu.less';

const { SubMenu } = Menu;

const { Sider } = Layout;

type SiderMenuProps = {

  collapsed: boolean;
  handleOnCollapse: () => void;


};
const SiderMenu: React.FC<SiderMenuProps> = ({
  collapsed, handleOnCollapse
}) => {

  const theme = 'light';

  const navigate = useNavigate();
  const [widthSidebar,setWidthSidebar] = useState(200);

  const handleSetwidthSidebar = () =>{
    setWidthSidebar(0);
  }
  const handleSiderMenuClick = (action: any) => {
    console.log('menu:', action);
    switch (action.key) {
      case 'dashboard':
        navigate('/');
        break;
      case 'showProducts':
        navigate('/products');
        break;
      case 'showDemandPlanning':
        navigate("/demand-planning");
        break;
      case 'showSupplyPlanning':
        navigate("/supply-planning");
        break;
      case 'showInventoryPlanning':
        navigate("/inventory-planning");
        break;
      case 'showShipmentPlanning':
        navigate("/shipment-planning");
        break;
      case 'showDeliveryPlanning':
        navigate("/delivery-planning");
        break;
      case 'showProductionPlanning':
        navigate("/production-planning");
        break;
      default:
        navigate('/');
    }
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="80"
      onCollapse={handleOnCollapse}
      collapsed={collapsed}
      width={widthSidebar}
      theme={theme}
    >
      <a>
        <div className="menu-logo" onClick={handleSetwidthSidebar} />
      </a>
      <Menu mode="inline" theme={theme} onClick={handleSiderMenuClick}>

        <SubMenu key="sub1" icon={<AppstoreOutlined />} title="Planning">

          <Menu.Item key="showDemandPlanning">
        
            <span className="nav-text">Demand planning</span>
          </Menu.Item>
          <Menu.Item key="showSupplyPlanning">
        
          <span className="nav-text">Supply planning</span>
        </Menu.Item>
        <Menu.Item key="showProductionPlanning">
        
          <span className="nav-text">Production planning</span>
        </Menu.Item>


        <Menu.Item key="showInventoryPlanning">
         
          <span className="nav-text">Inventory planning</span>
        </Menu.Item>

        <Menu.Item key="showShipmentPlanning">
       
          <span className="nav-text">Shipment planning</span>
        </Menu.Item>

        <Menu.Item key="showDeliveryPlanning">
        
          <span className="nav-text">Delivery planning</span>
        </Menu.Item>

        </SubMenu>



       
        <SubMenu key="sub3" icon={<BarChartOutlined />} title="Reports">
          <Menu.Item key="2">Inventory Aging</Menu.Item>
          <Menu.Item key="3">Supply chain Performance</Menu.Item>
        
          
        </SubMenu>

      
        <SubMenu key="sub2" icon={<SettingOutlined />} title="Setting">
          <Menu.Item key="9">Products</Menu.Item>
          <Menu.Item key="10">Customers</Menu.Item>
          <Menu.Item key="11">Vendors</Menu.Item>
          
        </SubMenu>

  
      </Menu>
    </Sider>
  );
}

export default SiderMenu;