import React, { useState, useContext } from 'react';
import { Layout } from 'antd';
import SiderMenu from '../SiderMenu/SiderMenu';
import LayoutBanner from '../LayoutBanner/LayoutBanner';

//import RoutingList from '../RoutingList/RoutingList';
//import Login from '../../domains/Login/Login'
import { If, Else, Then } from 'react-if'
import UserContext from '../../contexts/UserContext'
import { Outlet, Route } from 'react-router-dom';
//import PublicRoutingList from '../PublicRoutingList';
import { useLocation } from 'react-router-dom';
const { Content } = Layout;

const MainLayout = () => {
  const location = useLocation();
  const { isLoggedIn } = useContext(UserContext)

  const [collapsed, setCollapsed] = useState(false);

  const handleOnCollapse = () => {
    setCollapsed(prevState => !prevState);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <If condition={isLoggedIn}>
        <Then>
          <SiderMenu collapsed={collapsed} handleOnCollapse={handleOnCollapse}/>
          <Layout>
            <LayoutBanner
              collapsed={collapsed}
              handleOnCollapse={handleOnCollapse}
              loggedin={isLoggedIn}
            />
            <Content style={{ margin: '24px 16px 0' }}>
              <div style={{ padding: 24, background: '#fff', minHeight: 20 }}>
                <Outlet />
              </div>
            </Content>
          </Layout>
        </Then>
        <Else>
          <Then>
            <SiderMenu collapsed={collapsed} handleOnCollapse={handleOnCollapse}/>
            <Layout>
            <LayoutBanner
              collapsed={collapsed}
              handleOnCollapse={handleOnCollapse}
              loggedin={isLoggedIn}
            />
             <Content>
              
             <Outlet />
             </Content>
            </Layout>
          </Then>
        </Else>
      </If>


    </Layout>
  );
}

export default MainLayout;