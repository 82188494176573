import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import 'antd/dist/antd.css';
import './App.scss';
import Dashboard from '../Dashboard';
import LoginPage from '../LoginPage';
import MainLayout from '../MainLayout';
import PageNotFound from '../PageNotFound';
import PrivateRoute from '../PrivateRoute';
import ContextProvider from '../../contexts/ContextProvider';
import Home from '../../domains/Home';
import DemandPlanning from '../../domains/DemandPlanning';
import SupplyPlanning from '../../domains/SupplyPlanning';
import InventoryPlanning from '../../domains/InventoryPlanning';
import ProductionPlanning from '../../domains/ProductionPlanning';
import ShipmentPlanning from '../../domains/ShipmentPlanning';
import DeliveryPlanning from '../../domains/DeliveryPlanning';
//https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx
function App() {
  return (
    <ContextProvider>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path="/demand-planning" element={<DemandPlanning />} />
          <Route path="/supply-planning" element={<SupplyPlanning />} />
          <Route path="/inventory-planning" element={<InventoryPlanning />} />
          <Route path="/production-planning" element={<ProductionPlanning />} />
          <Route path="/shipment-planning" element={<ShipmentPlanning />} />
          <Route path="/delivery-planning" element={<DeliveryPlanning />} />

          <Route path="*" element={<PageNotFound />}></Route>

        </Route>
      </Routes>
    </ContextProvider>

  );
}

export default App;
