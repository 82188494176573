import { Table } from 'antd';
import React from 'react'



const DemandPlanList = () => {
  const dataSource = [
    {
      key: '1',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
    {
      key: '2',
      year_plan: 2022,
      customer_code: "cust",
      customer_plant: "plant",
      product_code: "product",
      grade_code: "grade",
      size_code: "size",
      unit: "Ton",
      source: "source",
      carry_over: 150000.56,
      mth1: 150000.56,
      mth2: 150000.56,
      mth3: 150000.56,
      mth4: 150000.56,
      mth5: 150000.56,
      mth6: 150000.56,
      mth7: 150000.56,
      mth8: 150000.56,
      mth9: 150000.56,
      mth10: 150000.56,
      mth11: 150000.56,
      mth12: 150000.56,
    },
  ];

  const columns = [
    {
      title: 'Year',
      dataIndex: 'year_plan',
      key: 'year_plan',
      fixed: true,
      width: 80,

    },
    {
      title: 'Customer',
      dataIndex: 'customer_code',
      key: 'customer_code',
      width: 120,
      fixed: true,
    },
    {
      title: 'Plant',
      dataIndex: 'customer_plant',
      key: 'customer_plant',
      width: 80,
      fixed: true,
    },
    {
      title: 'Product',
      dataIndex: 'product_code',
      key: 'product_code',
      width: 120,
      fixed: true,
    },
    {
      title: 'Grade/Spec',
      dataIndex: 'grade_code',
      key: 'grade_code',
      width: 120,
      fixed: true,
    },
    {
      title: 'Size',
      dataIndex: 'size_code',
      key: 'size_code',
      width: 120,
      fixed: false,
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: 120,
      fixed: false,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 120,
      fixed: false,
    },
    {
      title: 'Carry Over',
      dataIndex: 'carry_over',
      key: 'carry_over',
      width: 120,
    },
    {
      title: 'Jan',
      dataIndex: 'mth1',
      key: 'mth1',
      width: 120,
    },
    {
      title: 'Feb',
      dataIndex: 'mth2',
      key: 'mth2',
      width: 120,
    },
    {
      title: 'Mar',
      dataIndex: 'mth3',
      key: 'mth3',
      width: 120,
    },
    {
      title: 'Apr',
      dataIndex: 'mth4',
      key: 'mth4',
      width: 120,
    },
    {
      title: 'May',
      dataIndex: 'mth5',
      key: 'mth5',
      width: 120,
    },
    {
      title: 'Jun',
      dataIndex: 'mth6',
      key: 'mth6',
      width: 120,
    },
    {
      title: 'Jul',
      dataIndex: 'mth7',
      key: 'mth7',
      width: 120,
    },
    {
      title: 'Aug',
      dataIndex: 'mth8',
      key: 'mth8',
      width: 120,
    },
    {
      title: 'Sep',
      dataIndex: 'mth9',
      key: 'mth9',
      width: 120,
    },
    {
      title: 'Oct',
      dataIndex: 'mth10',
      key: 'mth10',
      width: 120,
    },
    {
      title: 'Nov',
      dataIndex: 'mth11',
      key: 'mth11',
      width: 120,
    },
    {
      title: 'Dec',
      dataIndex: 'mth12',
      key: 'mth12',
      width: 120,
    },
  ];
  return (
    <Table dataSource={dataSource} columns={columns} scroll={{ x: 200 ,y:200}}  />

  )
}

export default DemandPlanList