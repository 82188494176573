import React,{useState,useEffect,createContext} from 'react'
import { UserResponse } from '../types/hooks/user'
import { useCookies } from 'react-cookie';


type UserContextProps = {
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
    accessToken: string
    setAccessToken: React.Dispatch<React.SetStateAction<string>>
    user: UserResponse
    setUser: React.Dispatch<React.SetStateAction<UserResponse>>


}

const UserContext = createContext({} as UserContextProps)

export const UserProvider: React.FC = (props) => {
    const isLoggedInKey = 'isLoggedIn'
    const accessTokenKey = 'accessToken'
    const userKey = 'user'
    const empKey = 'emp'

    const isLoggedInLocal = localStorage.getItem(isLoggedInKey)
    const accessTokenKeyInLocal = localStorage.getItem(accessTokenKey)
    const userKeyInlocal = localStorage.getItem(userKey)

    const empKeyInlocal = localStorage.getItem(empKey)

    const [isLoggedIn,setIsLoggedIn] = useState<boolean>(isLoggedInLocal ? JSON.parse(isLoggedInLocal) : false)
    const [accessToken,setAccessToken] = useState<string>(accessTokenKeyInLocal ? JSON.parse(accessTokenKeyInLocal) : '')
    const [user,setUser] = useState<UserResponse>(userKeyInlocal ? JSON.parse(userKeyInlocal) : {})
    //const [emp,setEmp] = useState<Employee>(empKeyInlocal ? JSON.parse(empKeyInlocal) : {})
   // const [drives,setDrives] = useState<Drive[]>([])
   // const [driveData,setDriveData] = useState<Drive[]>([])
    //const [cookies, setCookie, removeCookie] = useCookies(['sso']);

    useEffect(() => {
        localStorage.setItem(isLoggedInKey,JSON.stringify(isLoggedIn))

    },[isLoggedIn])

    useEffect(() => {
        localStorage.setItem(accessTokenKey,JSON.stringify(accessToken))

    },[accessToken])

    useEffect(() => {
        localStorage.setItem(userKey,JSON.stringify(user))

    },[user])

    // useEffect(() => {
    //     localStorage.setItem(empKey,JSON.stringify(emp))

    // },[emp])

   

    return (
        <UserContext.Provider
            value={
                {
                    isLoggedIn,
                    accessToken,
                    user,
                    setIsLoggedIn,
                    setAccessToken,
                    setUser,
                    // drives,
                    // setDrives,
                    // emp,
                    // setEmp,
                    // driveData,
                    // setDriveData,
                }
            }
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext