import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'

const PrivateRoute = ({children}:any) => {
    let location = useLocation()
    const {
      isLoggedIn,
      accessToken,
      setIsLoggedIn,
      setAccessToken,
      setUser,
      user
  } = useContext(UserContext)
    
  console.log("isLoggedIn");  
  console.log(isLoggedIn);  
  if(!isLoggedIn){
    return <Navigate to="/login" state={{ from: location }} replace />;
  
  }

  return children;
}

export default PrivateRoute