import { DownOutlined } from '@ant-design/icons'
import { Button, Card, Col, Dropdown, Menu, PageHeader, Row, Typography } from 'antd'
import React from 'react'
import DemandPlanChart from '../../components/DemandPlanChart'
import DemandPlanList from '../../components/DemandPlanList'

const { Title } = Typography
const menu = (
  <Menu>
    <Menu.Item key="0">
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        Annual plan
      </a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1">
      <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
        Calculate Forecast
      </a>
    </Menu.Item>
    
    {/* <Menu.Item key="3" disabled>
      3rd menu item（disabled）
    </Menu.Item> */}
  </Menu>
);
const DemandPlanning = () => {
  return (
    <>
    
     
    <Row style={{backgroundColor:'white',padding:'5px 5px'}}>
      <Col span={24}>
      <PageHeader
      className="site-page-header"
      
      onBack={() => window.history.back()}
      title="Demand Planning"
      // subTitle="This is a subtitle"
      extra={[
        <Dropdown overlay={menu}>
    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
      Update Data <DownOutlined />
    </a>
  </Dropdown>
        // <Button key="3">Operation</Button>,
        // <Button key="2">Operation</Button>,
        // <Button key="1" type="primary">
        //   Primary
        // </Button>,
      ]}
    >
     
    </PageHeader>
      </Col>
          <Col span={24}>
            <DemandPlanChart/>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DemandPlanList/>
          </Col>
        </Row>

    </>


  )
}

export default DemandPlanning